export function gettoken() {
    let loc_medata = window.localStorage.getItem('me');
    // if (!loc_medata) {
    //   return null;
    // }
    let medata = JSON.parse(loc_medata)
    if (!medata) {
        return null;
    }
    return medata.token;
}