<template>
    <v-app-bar app dark dense class="primary" :clipped-left="$vuetify.breakpoint.lgAndUp">
        <v-app-bar-nav-icon @click="touchdrawer" />
        <v-toolbar-title>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-app-bar>
</template>
<script>
import { event_on, event_off, event_emit } from '@/eventbus'

export default {
    components: {},
    data() {
        return {
            events:[]
        };
    },
    watch: {
        // '$route'(to, from) {
        // }
    },
    props: [],
    mounted: function() {
        this.events.forEach(event => event_on(this, event));
    },
    beforeDestroy() {
        this.events.forEach(event => event_off(this, event));
    },
    methods: {
        touchdrawer() {
            event_emit(this, 'touchdrawer');
        },
    },
};
</script>