<template>
    <v-dialog v-model="show" max-width="600px" persistent>
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="3" md="3">
                            <v-select
                              v-model="model.tag"
                              label="tag"
                              :items="tags"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                            <v-select
                              v-model="model.obj_tp"
                              label="obj_tp"
                              :items="obj_tps"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                            <v-text-field v-model="model.obj_pk" label="obj_pk"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                            <v-text-field v-model="model.media_id" label="media_id"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">取消</v-btn>
                <v-btn color="blue darken-1" text @click="save">确认</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { event_on, event_off } from '@/eventbus'
import { objmedia_create } from '@/api'

export default {
    components: {},
    data() {
        return {
            events: [
                'dialog_objmedia_show',
            ],
            rules: {
                required: value => !!value || '必须.',
                min: v => v.length >= 8 || '最小长度8',
            },
            show: false,
            model: {obj_tp: null, obj_pk: 0, media: {}, tag: ''},
            obj: {},
            crud: { type: 'u', index: -1 },
            handler: null,
            upfile: null,
            tags: ['thumbnail', 'files'],
            obj_tps: ['product', 'task', 'taskprogress'],
        }
    },
    mounted: function() {
        this.events.forEach(event => event_on(this, event));
    },
    beforeDestroy() {
        this.events.forEach(event => event_off(this, event));
    },
    computed: {
        formTitle() {
            if (this.crud.type == 'u') {
                return '更新'
            }
            return '新建';
        },
    },
    methods: {
        dialog_objmedia_show(data) {
            if (data.model) {
                this.model = data.model
            }
            this.handler = data.handler
            this.crud = data.crud
            this.$nextTick(() => {
                this.show = true;
            })
        },
        close() {
            this.show = false;
        },
        save() {
            objmedia_create(this.model).then(res => {
                console.log(res);
                this.handler()
                this.close()
            });
        },
    },
}
</script>