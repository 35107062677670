<template>
    <v-dialog v-model="show" max-width="600px" persistent>
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field v-model="model.name" label="名称"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field v-model="model.point_g" label="光"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field v-model="model.point_z" label="仔"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field v-model="model.count" label="数量"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea name="input-3-1" rows="3" label="详情" hide-details outlined v-model="model.desc"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-checkbox
                              v-model="model.is_publish"
                              label="发布"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">取消</v-btn>
                <v-btn color="blue darken-1" text @click="save">确认</v-btn>
            </v-card-actions>
            <DialogMediaSelect />
        </v-card>
    </v-dialog>
</template>
<script>
import { event_on, event_off } from '@/eventbus'
import { product_patch, product_create } from '@/api'
import DialogMediaSelect from '@/components/DialogMediaSelect.vue'

export default {
    components: {
        DialogMediaSelect
    },
    data() {
        return {
            events: [
                'dialog_product_show',
            ],
            rules: {
                required: value => !!value || '必须.',
                min: v => v.length >= 8 || '最小长度8',
            },
            show: false,
            model: {wx_data:{}, thumbnail: { static_url: null}, files: []},
            crud: {type: 'u', index: -1},
            handler: null
        }
    },
    mounted: function() {
        this.events.forEach(event => event_on(this, event));
    },
    beforeDestroy() {
        this.events.forEach(event => event_off(this, event));
    },
    computed: {
        formTitle() {
            if (this.crud.type=='u') {
                return '更新'
            }
            return '新建';
        },
    },
    methods: {
        dialog_product_show(data) {
            this.crud = data.crud
            this.model = data.model;
            if (this.crud.type === 'c') {
                this.model = {wx_data:{}, thumbnail: { static_url: null}, files: []}
            }
            this.handler = data.handler


            this.$nextTick(() => {
                this.show = true;
            })
        },
        close() {
            this.show = false;
        },
        save() {
            if (this.crud.type == 'c') {
                product_create(this.model).then(res=>{
                    console.log(res)
                    this.close()
                    this.handler()
                })
                return
            }

            if (this.crud.type == 'u') {
                var entry = Object.assign({}, this.model)
                delete entry.createat
                delete entry.creator
                delete entry.thumbnail
                delete entry.files
                product_patch(entry).then(res=>{
                    console.log(res)
                    this.close()
                    this.handler()
                })
                return
            }
        },
    },
}
</script>
