<template>
    <v-dialog v-model="show" max-width="600px" persistent>
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field v-model="model.title" label="名称"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea name="input-3-1" rows="3" label="详情" hide-details outlined v-model="model.desc"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-col>
                            <v-file-input accept="image/*" hide-input @change="fileselected">
                            </v-file-input>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex child-flex" cols="8">
                            <v-card flat tile class="d-flex">
                                <v-img :src="model.static_url" aspect-ratio="1" class="grey lighten-2">
                                    <template v-slot:placeholder>
                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">取消</v-btn>
                <v-btn color="blue darken-1" text @click="save">确认</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { event_on, event_off } from '@/eventbus'
import { media_create } from '@/api'
import { photoCompress, convertBase64UrlToBlob } from '@/imageutil'

export default {
    components: {},
    data() {
        return {
            events: [
                'dialog_media_show',
            ],
            rules: {
                required: value => !!value || '必须.',
                min: v => v.length >= 8 || '最小长度8',
            },
            show: false,
            model: { static_url: null },
            crud: { type: 'u', index: -1 },
            handler: null,
            upfile: null,
        }
    },
    mounted: function() {
        this.events.forEach(event => event_on(this, event));
    },
    beforeDestroy() {
        this.events.forEach(event => event_off(this, event));
    },
    computed: {
        formTitle() {
            if (this.crud.type == 'u') {
                return '更新'
            }
            return '新建';
        },
    },
    methods: {
        dialog_media_show(data) {
            this.handler = data.handler
            this.crud = data.crud
            this.$nextTick(() => {
                this.show = true;
            })
        },
        close() {
            this.show = false;
        },
        save() {
            var formData = new FormData();
            formData.append("file", this.upfile);
            formData.append('title', this.model.title)
            formData.append('desc', this.model.desc)
            media_create(formData).then(res => {
                console.log(res);
                this.handler()
                this.close()
            });
        },
        fileselected(file) {
            if (!file) { return; }
            var upfile = file
            this.model.static_url = window.URL.createObjectURL(file);
            if (file.size / 1014 > 500) {
                photoCompress(upfile, { quality: 0.2, },
                    base64Codes => {
                        upfile = convertBase64UrlToBlob(base64Codes);
                        upfile.name = file.name;
                        this.upfile = upfile
                    }
                );
            } else {
                this.upfile = upfile
            }
        }
    },
}
</script>