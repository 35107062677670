<template>
    <v-app>
        <router-view name="nav"></router-view>
        <router-view name="toolbar"></router-view>
        <v-main>
            <SnackBar />
            <router-view></router-view>
        </v-main>
    </v-app>
</template>
<script>
import SnackBar from '@/components/SnackBar';

export default {
    name: 'App',

    components: {
        SnackBar,
    },

    data: () => ({
        //
    }),
};
</script>