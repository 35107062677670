<template>
    <v-dialog v-model="show" max-width="1200px" persistent>
        <v-card>
            <div class="bigtable">
                <v-data-table :show-select="true" :items-per-page="30" :headers="headers" :items="desserts" dense :options.sync="options" :loading="loading" :server-items-length="total" loading-text="加载" :footer-props="footer_options" rowsPerPageText="ddd" @item-selected="item_selected">
                    <template v-slot:top>
                        <v-container fluid pa-4>
                            <v-row>
                                <v-col sm="2">
                                    <v-text-field label='标题' dense hide-details v-model="search.name" @click:clear="clean_search('name')" @change="searchdata" clearable></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)" disabled>
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                    <template v-slot:item.path="{ item }">
                        <v-avatar color="primary" size="64">
                            <img alt="Avatar" :src="item.path" />
                        </v-avatar>
                    </template>
                </v-data-table>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">取消</v-btn>
                <v-btn color="blue darken-1" text @click="save">确定</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { media_list } from '@/api'
import { event_on, event_off } from '@/eventbus'
import { formatDate } from '@/date'

export default {
    data() {
        return {
            events: [
                'dialog_media_select_show',
            ],
            show: false,
            total: 0,
            loading: false,
            options: {
                itemsPerPage: 20,
                sortBy: [],
                sortDesc: [],
                page: 1
            },
            footer_options: {
                showFirstLastPage: true,
                'items-per-page-options': [20, 30, 50]
            },
            search: {},
            historys: [],
            headers: [
                { text: '创建时间', value: 'createat', sortable: false },
                { text: '创建者', value: 'creator.username' },
                { text: '标题', value: 'title' },
                { text: '类型', value: 'filetype' },
                { text: '描述', value: 'desc' },
                { text: '路径', value: 'path' },
                { text: '操作', value: 'actions', sortable: false },
            ],
            desserts: [],
            selected: []
        }
    },
    mounted: function() {
        this.events.forEach(event => event_on(this, event));
    },
    beforeDestroy: function() {
        this.events.forEach(event => event_off(this, event));
    },
    filters: {
        formatDateTime: function(ts) {
            return formatDate(ts);
        },
        formatDate: function(ts) {
            return formatDate(ts, 'yyyy-MM-dd');
        },
    },
    computed: {},
    watch: {
        options: {
            handler() {
                this.fetchdata();
            },
            deep: true,
        },
    },
    props: [],
    methods: {
        dialog_media_select_show(data) {
            this.model = data.model;
            this.handler = data.handler
            this.crud = data.crud

            this.$nextTick(() => {
                this.show = true;
            })
        },
        fetchdata() {
            this.loading = true;
            var args = this.getargs()
            media_list(args, {}).then(res => {
                console.log(res);
                this.desserts = res.data
                this.loading = false;
                this.total = res.total
            })
        },
        getargs() {
            var options = this.options;
            var args = {
                page: options.page,
                count: options.itemsPerPage,
                sortBy: options.sortBy.join(","),
                sortDesc: options.sortDesc.join(","),
            }
            for (var item in this.search) {
                if (this.search[item]) {
                    args[item] = this.search[item];
                }
            }

            return args;
        },
        searchdata() {
            this.options.page = 1;
            this.fetchdata();
        },
        clean_search(attr) {
            this.search[attr] = null;
            this.searchdata();
        },

        item_selected(data) {
            if (data.value) {
                this.selected.push(data.item)
            } else {
                this.selected.forEach((item, index, arr) => {
                    if (item === data.item) {
                        arr.splice(index, 1)
                    }
                })
            }
        },
        close() {
            this.show = false;
        },
        save() {
            if (!this.desserts) {
                return
            }
            this.handler(this.selected);
            this.close();
        },
    },

}
</script>
<style scoped>
/*.bigtable {
    overflow: scroll;
}

.v-data-table {
    width: 1200px;
}*/
</style>