<template>
    <v-card>
        <div class="bigtable">
            <v-data-table :items-per-page="30" :headers="headers" :items="desserts" dense :options.sync="options" :loading="loading" :server-items-length="total" loading-text="加载" :footer-props="footer_options" rowsPerPageText="ddd">
                <template v-slot:top>
                    <v-container fluid pa-4>
                        <v-row>
                            <v-col md="1">
                                <v-btn color="primary" dark @click="addItem">新建</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)" disabled>
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </template>
                <template v-slot:item.path="{ item }">
                    <v-avatar color="primary" size="64">
                        <img alt="Avatar" :src="item.url"/>
                    </v-avatar>
                </template>
            </v-data-table>
        </div>
        <DialogMedia />
    </v-card>
</template>
<script>
import { media_list, media_patch } from '@/api'
import { event_on, event_off, event_emit } from '@/eventbus'
import { formatDate } from '@/date'
import DialogMedia from '@/components/DialogMedia.vue'

export default {
    components: {
        DialogMedia
    },
    data() {
        return {
            events: [],
            total: 0,
            loading: false,
            options: {
                itemsPerPage: 20,
                sortBy: [],
                sortDesc: [],
                page: 1
            },
            footer_options: {
                showFirstLastPage: true,
                'items-per-page-options': [20, 30, 50]
            },
            search: {},
            historys: [],
            headers: [
                { text: '创建时间', value: 'createat', sortable: false },
                { text: '创建者', value: 'creator.username' },
                { text: 'PK', value: 'id' },
                { text: '标题', value: 'title' },
                { text: '类型', value: 'filetype' },
                { text: '描述', value: 'desc' },
                { text: '路径', value: 'path' },
                { text: '操作', value: 'actions', sortable: false },
            ],
            desserts: [],
        }
    },
    props: [],
    methods: {
        fetchdata() {
            this.loading = true;
            var args = this.getargs()
            media_list(args, {}).then(res => {
                console.log(res);
                this.desserts = res.data
                this.loading = false;
                this.total = res.total

                this.desserts.forEach(item=>{
                    item.url = 'https://mpstatic.socialwiki.cn' + item.path
                })
            })
        },
        getargs() {
            var options = this.options;
            var args = {
                page: options.page,
                count: options.itemsPerPage,
                sortBy: options.sortBy.join(","),
                sortDesc: options.sortDesc.join(","),
            }
            for (var item in this.search) {
                if (this.search[item]) {
                    args[item] = this.search[item];
                }
            }

            return args;
        },
        searchdata() {
            this.options.page = 1;
            this.fetchdata();
        },
        clean_search(attr) {
            this.search[attr] = null;
            this.searchdata();
        },
        editItem(item) {
            var index = this.desserts.indexOf(item)
            var entry = Object.assign({}, item)

            var _this = this;
            let data = {
                model: entry,
                crud: { type: 'u', index: index },
                handler: function(md) {
                    console.log(md)
                    _this.fetchdata()
                }
            }
            event_emit(this, "dialog_media_show", data);
        },
        addItem() {
            var item = Object.assign({}, item)

            var _this = this;
            let data = {
                model: item,
                crud: { type: 'c', index: 0 },
                handler: function(md) {
                    console.log(md)
                    _this.fetchdata()
                }
            }
            event_emit(this, "dialog_media_show", data);
        },
        deleteItem(item) {
            const ret = confirm('是否删除?')
            if (!ret) {return;}

            let data = {id:item.id, is_delete: true}
            media_patch(data).then(res=>{
                console.log(res)
                const index = this.desserts.indexOf(item)
                this.desserts.splice(index, 1)
            })
        },
    },
    mounted: function() {
        this.events.forEach(event => event_on(this, event));
        this.fetchdata()
    },
    beforeDestroy() {
        this.events.forEach(event => event_off(this, event));
    },
    filters: {
        formatDateTime: function(ts) {
            return formatDate(ts);
        },
        formatDate: function(ts) {
            return formatDate(ts, 'yyyy-MM-dd');
        },
    },
    watch: {
        options: {
            handler() {
                console.log(this.options)
                this.fetchdata();
            },
            deep: true,
        },
    },
}
</script>
<style>
/* .maindialog {
  width: 420px;
  min-width:300px;
  margin:0 auto;
} */
</style>