import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import {
    gettoken
} from '@/utils'

Vue.config.productionTip = false

let bus = new Vue()
Vue.prototype.bus = bus

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.matched.some(route => route.meta.requireAuth)) {
        let token = gettoken();
        if (!token) {
            next({
                path: '/auth/signin',
                query: {
                    redirect: to.fullPath
                }
            })
        }
        next()
    } else {
        next()
    }
})

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')