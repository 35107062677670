<template>
    <v-card>
        <div class="bigtable">
            <v-data-table :items-per-page="30" :headers="headers" :items="desserts" dense :options="options" :loading="loading" :server-items-length="pagetotal" loading-text="加载" :footer-props="footer_options" rowsPerPageText="ddd">
                <template v-slot:top>
                    <v-container fluid pa-4>
                        <v-row>
                            <v-col md="1">
                                <v-btn color="primary" dark @click="addItem">新建</v-btn>
                            </v-col>
                            <v-col sm="2">
                                <v-text-field label='名称' dense hide-details v-model="search.name" @click:clear="clean_search('name')" @change="searchdata" clearable></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </template>
                <template v-slot:item.wx_data.avatarUrl="{ item }">
                    <v-avatar color="primary" size="36">
                        <img alt="Avatar" :src="item.wx_data.avatarUrl"/>
                    </v-avatar>
                </template>
            </v-data-table>
        </div>
        <DialogProduct />
    </v-card>
</template>
<script>
import { product_list } from '@/api'
import { event_on, event_off, event_emit } from '@/eventbus'
import { formatDate } from '@/date'
import DialogProduct from '@/components/DialogProduct.vue'

export default {
    components: {
        DialogProduct
    },
    data() {
        return {
            events: [],
            pagetotal: 0,
            loading: false,
            options: {
                itemsPerPage: 20,
                sortBy: [],
                sortDesc: []
            },
            footer_options: {
                showFirstLastPage: true,
                'items-per-page-options': [20, 30, 50]
            },
            search: {},
            historys: [],
            headers: [
                { text: '创建时间', value: 'createat', sortable: false },
                { text: 'PK', value: 'id' },
                { text: '发布', value: 'is_publish' },
                { text: '数量', value: 'count' },
                { text: '名称', value: 'name' },
                { text: '光', value: 'point_g' },
                { text: '仔', value: 'point_z' },
                { text: '描述', value: 'desc' },
                { text: '操作', value: 'actions', sortable: false },
            ],
            desserts: [],
        }
    },
    props: [],
    methods: {
        fetchdata() {
            this.loading = true;
            var args = this.getargs()
            product_list(args).then(res => {
                console.log(res);
                this.desserts = res.data
                this.loading = false;
                this.pagetotal = res.total
            })
        },
        getargs() {
            var options = this.options;
            var args = {
                page: options.page,
                count: options.itemsPerPage,
                sortBy: options.sortBy.join(","),
                sortDesc: options.sortDesc.join(","),
            }
            for (var item in this.search) {
                if (this.search[item]) {
                    args[item] = this.search[item];
                }
            }

            return args;
        },
        searchdata() {
            this.options.page = 1;
            this.fetchdata();
        },
        clean_search(attr) {
            this.search[attr] = null;
            this.searchdata();
        },
        editItem(item) {
            var index = this.desserts.indexOf(item)
            var entry = Object.assign({}, item)

            var _this = this;
            let data = {
                model: entry,
                crud: { type: 'u', index: index },
                handler: function() {
                    _this.fetchdata()
                }
            }
            event_emit(this, "dialog_product_show", data);
        },
        addItem() {
            var item = Object.assign({}, item)

            var _this = this;
            let data = {
                model: item,
                crud: { type: 'c', index: 0 },
                handler: function() {
                   _this.fetchdata()
                }
            }
            event_emit(this, "dialog_product_show", data);
        },
    },
    mounted: function() {
        this.events.forEach(event => event_on(this, event));
        this.fetchdata()
    },
    beforeDestroy() {
        this.events.forEach(event => event_off(this, event));
    },
    filters: {
        formatDateTime: function(ts) {
            return formatDate(ts);
        },
        formatDate: function(ts) {
            return formatDate(ts, 'yyyy-MM-dd');
        },
    },
    watch: {
        options: {
            handler() {
                this.fetchdata();
            },
            deep: true,
        },
    },
}
</script>
