<template>
    <v-app-bar app dark dense class="primary" clipped-left>
        <v-app-bar-nav-icon @click="touchdrawer" />
        <v-toolbar-title>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items v-for="(item, index) in routes" :key="index">
            <v-btn depressed class="primary" :ripple="false" :to="item.path">
                {{item.text}}
            </v-btn>
        </v-toolbar-items>
        <v-menu open-on-hover offset-y :nudge-width="100">
            <template v-slot:activator="{ on }">
                <v-toolbar-items>
                    <v-btn class="primary" :ripple="false" text small v-on="on">
                        <v-icon>account_box</v-icon>
                        <u>{{model.name}}</u>
                    </v-btn>
                </v-toolbar-items>
            </template>
            <v-list class="primary" dark dense>
                <v-list-item v-for="(item, index) in items" :key="index" :to="item.path">
                    <v-list-item-title class="caption">{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>
<script>
import { route_list } from '@/api'
import { event_on, event_off, event_emit } from '@/eventbus'
import gdata from '@/gdata';
export default {
    components: {},
    data() {
        return {
            events: ['signout', 'route_changed'],
            model: { name: '' },
            routes: null,
            items: [
                { title: '退出', path: '/auth/signout' },
                { title: '修改密码', path: '/auth/password/set' },
            ],
        };
    },
    watch: {
        '$route'(to, from) {
            // 对路由变化作出响应...
            console.log(from)
            this.model = gdata.get_medata();
            this.routes = gdata.get_data_routes();
            event_emit(this, 'route_changed', to);
        }
    },
    props: [],
    mounted: function() {
        this.events.forEach(event => event_on(this, event));
        this.model = gdata.get_medata();
        console.log('mounted toolbar')
        route_list().then(res => {
            console.log(res);
            gdata.set_data_routes(res.data)
            this.routes = res.data;
            event_emit(this, 'route_changed', this.$route);
        });
    },
    beforeDestroy() {
        this.model = null;
        this.events.forEach(event => event_off(this, event));
    },
    methods: {
        touchdrawer() {
            event_emit(this, 'touchdrawer');
        },
        signout() {
            this.model = null;
        },
        signin() {
            this.model = gdata.get_medata();
        },
        route_changed(route) {
            console.log(route)

            function getfirstlink(obj) {
                var item = obj[0]
                if (item.children) {
                    return getfirstlink(item.children)
                }

                return item
            }
            if (!this.routes) {
                return;
            }
            for (var i = this.routes.length - 1; i >= 0; i--) {
                if (this.routes[i].path === this.$route.path) {
                    var firstlink;
                    if (this.routes[i].children) {
                        firstlink = getfirstlink(this.routes[i].children);
                    } else {
                        firstlink = this.routes[i]
                    }

                    this.$router.replace(firstlink);
                    break;
                }
            }
        }
    },
};
</script>