<template>
    <v-dialog v-model="show" max-width="600px" persistent>
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field v-model="model.wx_data.nickName" label="昵称" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field v-model="model.point_g" label="光"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field v-model="model.point_z" label="仔"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">取消</v-btn>
                <v-btn color="blue darken-1" text @click="save">确认</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { event_on, event_off } from '@/eventbus'
import { user_patch, user_create } from '@/api'

export default {
    components: {
    },
    data() {
        return {
            events: [
                'dialog_user_show',
            ],
            rules: {
                required: value => !!value || '必须.',
                min: v => v.length >= 8 || '最小长度8',
            },
            show: false,
            model: {wx_data:{}},
            crud: {type: 'u', index: -1},
            handler: null
        }
    },
    mounted: function() {
        this.events.forEach(event => event_on(this, event));
    },
    beforeDestroy() {
        this.events.forEach(event => event_off(this, event));
    },
    computed: {
        formTitle() {
            if (this.crud.type=='u') {
                return '更新'
            }
            return '新建';
        },
    },
    methods: {
        dialog_user_show(data) {
            this.model = data.model;
            this.handler = data.handler

            this.$nextTick(() => {
                this.show = true;
            })
        },
        close() {
            this.show = false;
        },
        save() {
            if (this.crud.type == 'c') {
                user_create(this.model).then(res=>{
                    console.log(res)
                    this.close()
                    this.handler()
                })
                return
            }

            if (this.crud.type == 'u') {
                let data = {id: this.model.id, point_g: this.model.point_g, point_z:this.model.point_z}
                user_patch(data).then(res=>{
                    console.log(res)
                    this.close()
                    this.handler()
                })
                return
            }
        },
    },
}
</script>
