function event_on(t, name) {
    t.bus.$on('event_' + name, t[name]);
}

function event_off(t, name) {
    t.bus.$off('event_' + name, t[name]);
}

function event_emit(s, name, data) {
    s.bus.$emit('event_' + name, data);
}

function event_fire(document, element, evt_name) {
    var event; // The custom event that will be created

    if (document.createEvent) {
        event = document.createEvent("HTMLEvents");
        event.initEvent(evt_name, true, true);
    } else {
        event = document.createEventObject();
        event.eventType = evt_name;
    }

    event.eventName = evt_name;
    if (document.createEvent) {
        element.dispatchEvent(event);
    } else {
        element.fireEvent("on" + event.eventType, event);
    }
}


export {
    event_on,
    event_off,
    event_emit,
    event_fire
};