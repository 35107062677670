<template>
    <v-navigation-drawer :drawer="drawer" :mini-variant.sync="mini" :clipped="true" app class="blue-grey darken-4" overflow width="210" permanent>
        <v-list dense class="pa-0">
            <v-divider></v-divider>
            <template v-for="item in items">
                <v-row v-if="item.heading" :key="item.heading" align="center">
                    <v-col cols="6">
                        <v-subheader v-if="item.heading">
                            {{ item.heading }}
                        </v-subheader>
                    </v-col>
                    <v-col cols="6" class="text-center">
                        <a href="#!" class="body-2">EDIT</a>
                    </v-col>
                </v-row>
                <v-list-group v-else-if="item.children" :key="item.text">
                    <template v-slot:activator >
                        <v-list-item-content >
                            <v-list-item-title class="white--text body-2 font-weight-bold">
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item v-for="(child, i) in item.children" :key="i" link :to="child.path">
                        <v-list-item-action v-if="child.icon">
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <v-icon class="grey--text" v-on="on">{{ child.icon }}</v-icon>
                                </template>
                                <span>{{child.text}}</span>
                            </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-content class="grey--text">
                            <v-list-item-title>
                                {{ child.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item v-else :key="item.text" link :to="item.path" class="white--text">
                    <v-list-item-action>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon class="grey--text" v-on="on">{{ item.icon }}</v-icon>
                            </template>
                            <span>{{item.text}}</span>
                        </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-content class="grey--text">
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>
<script>

import { event_on, event_off } from '@/eventbus'
import gdata from '@/gdata';

export default {
    props: {},
    watch: {
        // '$route'(to, from) {
        //     // this.route_changed(to);
        // }
    },
    data: () => ({
        events: ['touchdrawer', 'route_changed', 'route_list_done'],
        routes: null,
        drawer: true,
        mini: false,
        items: [],
    }),
    methods: {
        touchdrawer() {
            this.mini = !this.mini;
        },
        route_list_done(res) {
            this.routes = res.data;
        },
        route_changed(route) {
            console.log(route)
            this.routes = gdata.get_data_routes();
            this.items = this.routes
            console.log(this.items)
        }
    },
    mounted: function() {
        this.events.forEach(event => event_on(this, event));
    },
    beforeDestroy() {
        this.events.forEach(event => event_off(this, event));
    },
}
</script>