<template>
    <v-snackbar v-model="show" :bottom="model.y === 'bottom'" :left="model.x === 'left'" :multi-line="model.mode === 'multi-line'" :right="model.x === 'right'" :timeout="model.timeout" :top="model.y === 'top'" :vertical="model.mode === 'vertical'" color="primary" class="text-md-center">
        {{ model.msg }}
    </v-snackbar>
</template>
<script>
import { event_on, event_off } from '@/eventbus'
export default {
    data: () => ({
        events: ['snackbar'],
        show: false,
        model: { msg: '', y: 'bottom', x: null, mode: '', timeout: 1600, color: '' }
    }),
    mounted: function() {
        this.events.forEach(event => event_on(this, event));
    },
    beforeDestroy() {
        this.model = null;
        this.events.forEach(event => event_off(this, event));
    },
    methods: {
        snackbar(data) {
            for (var key in data) {
                this.model[key] = data[key];
            }
            this.show = true;
        },
    },
}
</script>
<style>
</style>