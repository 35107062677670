/*
 *获取今日的起始和结束时间
 *返回值：'起始时间,结束时间'
 */
export function ToDayStr() {
    var returnStr = ''
    var date = new Date()
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    // var hour = date.getHours()
    // var minutes = date.getMinutes()
    // var second = date.getSeconds()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    returnStr = year + month + day + '000000,'
    returnStr += year + month + day + '235959'
    return returnStr
}

/*
 *获取昨日的起始和结束时间
 *返回值：'起始时间,结束时间'
 */
export function YesterDayStr() {
    var date = GetDate(1, 1)
    var returnStr = ''
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    returnStr = year + month + day + '000000,'
    returnStr += year + month + day + '235959'
    return returnStr
}

/*
 *获取本周的起始和结束时间
 *返回值：'起始时间,结束时间'
 */
export function ThisWeekStr() {
    var returnStr = ''
    var date = new Date()
    var week = date.getDay()
    var monday = GetDate2(week - 1, 1, date)
    var sunday = GetDate2(7 - week, 2, date)
    // 起始时间的年月日
    var year1 = monday.getFullYear()
    var month1 = monday.getMonth() + 1
    var day1 = monday.getDate()
    // 结束时间的年月日
    var year2 = sunday.getFullYear()
    var month2 = sunday.getMonth() + 1
    var day2 = sunday.getDate()
    // 处理起始时间小于10的追加'0'在前面
    month1 = month1 < 10 ? '0' + month1 : month1
    day1 = day1 < 10 ? '0' + day1 : day1
    // 处理结束时间小于10的追加'0'在前面
    month2 = month2 < 10 ? '0' + month2 : month2
    day2 = day2 < 10 ? '0' + day2 : day2

    returnStr = year1 + month1 + day1 + '000000,'
    returnStr += year2 + month2 + day2 + '235959'
    return returnStr
}

/*
 *获取上周的起始和结束时间
 *返回值：'起始时间,结束时间'
 */
export function LastWeekStr() {
    var returnStr = ''
    var date = new Date()
    var week = date.getDay()
    var monday = GetDate2(week + 6, 1, date)
    var sunday = GetDate2(week, 1, date)
    // 起始时间的年月日
    var year1 = monday.getFullYear()
    var month1 = monday.getMonth() + 1
    var day1 = monday.getDate()
    // 结束时间的年月日
    var year2 = sunday.getFullYear()
    var month2 = sunday.getMonth() + 1
    var day2 = sunday.getDate()
    // 处理起始时间小于10的追加'0'在前面
    month1 = month1 < 10 ? '0' + month1 : month1
    day1 = day1 < 10 ? '0' + day1 : day1
    // 处理结束时间小于10的追加'0'在前面
    month2 = month2 < 10 ? '0' + month2 : month2
    day2 = day2 < 10 ? '0' + day2 : day2

    returnStr = year1 + month1 + day1 + '000000,'
    returnStr += year2 + month2 + day2 + '235959'
    return returnStr
}

/*
 *获取本月的起始和结束时间
 *返回值：'起始时间,结束时间'
 */
export function ThisMonthStr() {
    var returnStr = ''
    var date = new Date()
    var year = date.getFullYear()
    var month = date.getMonth()

    var min = new Date(year, month, 1)
    var max = new Date(year, month + 1, 0)

    // 起始时间的年月日
    var year1 = min.getFullYear()
    var month1 = min.getMonth() + 1
    var day1 = min.getDate()
    // 结束时间的年月日
    var year2 = max.getFullYear()
    var month2 = max.getMonth() + 1
    var day2 = max.getDate()
    // 处理起始时间小于10的追加'0'在前面
    month1 = month1 < 10 ? '0' + month1 : month1
    day1 = day1 < 10 ? '0' + day1 : day1
    // 处理结束时间小于10的追加'0'在前面
    month2 = month2 < 10 ? '0' + month2 : month2
    day2 = day2 < 10 ? '0' + day2 : day2

    returnStr = year1 + month1 + day1 + '000000,'
    returnStr += year2 + month2 + day2 + '235959'
    return returnStr
}

/*
 *获取当前日期前N天或后N日期(N = day)
 *type:1：前；2：后
 */
export function GetDate(day, type) {
    var zdate = new Date()
    var edate
    if (type === 1) {
        edate = new Date(zdate.getTime() - (day * 24 * 60 * 60 * 1000))
    } else {
        edate = new Date(zdate.getTime() + (day * 24 * 60 * 60 * 1000))
    }
    return edate
}

/*
 *获取传入的日期前N天或后N日期(N = day)
 *type:1：前；2：后
 *date：传入的日期
 */
export function GetDate2(day, type, date) {
    var zdate
    if (date === null || date === undefined) {
        zdate = new Date()
    } else {
        zdate = date
    }
    var edate
    if (type === 1) {
        edate = new Date(zdate.getTime() - (day * 24 * 60 * 60 * 1000))
    } else {
        edate = new Date(zdate.getTime() + (day * 24 * 60 * 60 * 1000))
    }
    return edate
}

export function formatDate(ts, fmt = "yyyy-MM-dd hh:mm") {
    if (ts === 0) {
        return '';
    }
    var date = new Date((ts - 28800) * 1000);
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

export function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}

export function formatDate2(timestamp) {
    var date = new Date((timestamp - 28800) * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = date.getDate() + ' ';
    let h = date.getHours() + ':';
    let m = date.getMinutes() + ':';
    let s = date.getSeconds();
    return Y + M + D + h + m + s;
}


export function getDays(start, end) {
    var strSeparator = "-"; //日期分隔符
    var oDate1;
    var oDate2;
    var iDays;
    oDate1 = start.split(strSeparator);
    oDate2 = end.split(strSeparator);
    var strDateS = new Date(oDate1[0] + "-" + oDate1[1] + "-" + oDate1[2]);
    var strDateE = new Date(oDate2[0] + "-" + oDate2[1] + "-" + oDate2[2]);
    iDays = parseInt(Math.abs(strDateS-strDateE)/1000/60/60/24); //把相差的毫秒数转换为天数

    return iDays + 1;
}


export function DateFormat(date, fmt) {
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };

    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }

    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }

    return fmt;
}