import axios from 'axios';

// axios.defaults.withCredentials = false;

var API_BASE_URL = 'https://mp.socialwiki.cn/api'
// var API_BASE_URL = 'http://127.0.0.1:9000/api'

function gettoken() {
    let loc_medata = window.localStorage.getItem('me');
    if (!loc_medata) {
      return null;
    }
    let medata = JSON.parse(loc_medata)
    if (!medata) {
        return null;
    }
    return medata.token;
}

function request(url, method, args, data) {
    const _url = API_BASE_URL + url
    const header = {
        'Content-Type': 'application/application/json',
        // 'Access-Control-Allow-Origin': '*',
        'Authorization': 'Token ' + gettoken()
    }
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: _url,
            params: args,
            data: data,
            headers: header,
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 小程序的promise没有finally方法，自己扩展下
 */
// Promise.prototype.finally = export function (callback) {
//   var Promise = this.constructor;
//   return this.then(
//     export function (value) {
//       Promise.resolve(callback()).then(
//         export function () {
//           return value;
//         }
//       );
//     },
//     export function (reason) {
//       Promise.resolve(callback()).then(
//         export function () {
//           throw reason;
//         }
//       );
//     }
//   );
// }


export function signin(data) {
    return request('/auth/signin/', 'post', {}, data)
}


export function signup(data) {
    return request('/auth/signup/', 'post', {}, data)
}



export function passwordreset(data) {
    return request('/user/password/reset', 'post', {}, data)
}



export function passwordset(data) {
    return request('/me/password/reset', 'post', {}, data)
}



export function route_list(data) {
    return request('/config/', 'get', {name:'menu'}, data)
}



export function task_list(data) {
    return request('/task/', 'get', {}, data)
}



export function task_create(data) {
    return request('/task/', 'post', {}, data)
}



export function task_detail(data) {
    let data_id = data.id
    delete data.id
    return request('/task/' + data_id + '/', 'get', {}, data)
}



export function task_patch(data) {
    let data_id = data.id
    delete data.id
    return request('/task/' + data_id + '/', 'post', {}, data)
}



export function review_list(data) {
    return request('/review/', 'get', {}, data)
}



export function review_create(data) {
    return request('/review/', 'post', {}, data)
}



export function review_detail(data) {
    let data_id = data.id
    delete data.id
    return request('/review/' + data_id + '/', 'get', {}, data)
}



export function review_patch(data) {
    let data_id = data.id
    delete data.id
    return request('/review/' + data_id + '/', 'post', {}, data)
}



export function usertask_list(data) {
    return request('/usertask/', 'get', {}, data)
}



export function usertask_create(data) {
    return request('/usertask/', 'post', {}, data)
}



export function usertask_detail(data) {
    let data_id = data.id
    delete data.id
    return request('/usertask/' + data_id + '/', 'get', {}, data)
}



export function usertask_patch(data) {
    let data_id = data.id
    delete data.id
    return request('/usertask/' + data_id + '/', 'post', {}, data)
}



export function taskprogress_list(data) {
    return request('/taskprogress/', 'get', {}, data)
}



export function taskprogress_create(data) {
    return request('/taskprogress/', 'post', {}, data)
}



export function taskprogress_detail(data) {
    let data_id = data.id
    delete data.id
    return request('/taskprogress/' + data_id + '/', 'get', {}, data)
}

export function taskprogress_patch(data) {
    let data_id = data.id
    delete data.id
    return request('/taskprogress/' + data_id + '/', 'post', {}, data)
}

export function usertaskpoint_list(data) {
    return request('/usertaskpoint/', 'get', {}, data)
}

export function usertaskpoint_create(data) {
    return request('/usertaskpoint/', 'post', {}, data)
}

export function usertaskpoint_detail(data) {
    let data_id = data.id
    delete data.id
    return request('/usertaskpoint/' + data_id + '/', 'get', {}, data)
}

export function usertaskpoint_patch(data) {
    let data_id = data.id
    delete data.id
    return request('/usertaskpoint/' + data_id + '/', 'post', {}, data)
}

export function media_list(args, data) {
    return request('/media/', 'get', args, data)
}

export function media_create(data) {
    return request('/media/', 'post', {}, data)
}

export function media_patch(data) {
    let data_id = data.id
    delete data.id
    return request('/media/' + data_id + '/', 'patch', {}, data)
}

export function transaction_list(data) {
    return request('/transaction/', 'get', {}, data)
}

export function noti_list(data) {
    return request('/noti/', 'get', {}, data)
}

export function noti_patch(data) {
    return request('/noti/', 'post', {}, data)
}

export function check_token(data) {
    return request('/u/check-token/', 'get', {}, data)
}

export function wxapp_login(data) {
    return request('/u/wxapp/login/', 'post', {}, data)
}

export function wxapp_userinfo(data) {
    return request('/u/wxapp/userinfo/', 'post', {}, data)
}

export function meinfo(data) {
    return request('/u/meinfo/', 'get', {}, data)
}

export function user_list(data) {
    return request('/user/', 'get', {}, data)
}

export function user_patch(data) {
    let data_id = data.id
    delete data.id
    return request('/user/' + data_id + '/', 'patch', {}, data)
}

export function product_list(args, data) {
    return request('/product/', 'get', args, data)
}

export function product_create(data) {
    return request('/product/', 'post', {}, data)
}

export function product_detail(data) {
    let data_id = data.id
    delete data.id
    return request('/product/' + data_id + '/', 'get', {}, data)
}

export function product_patch(data) {
    let data_id = data.id
    delete data.id
    return request('/product/' + data_id + '/', 'patch', {}, data)
}

export function user_product_list(args, data) {
    return request('/user_product/', 'get', args, data)
}

export function user_product_create(data) {
    return request('/user_product/', 'post', {}, data)
}

export function user_product_detail(data) {
    let data_id = data.id
    delete data.id
    return request('/user_product/' + data_id + '/', 'get', {}, data)
}

export function user_product_patch(data) {
    let data_id = data.id
    delete data.id
    return request('/user_product/' + data_id + '/', 'patch', {}, data)
}

export function objmedia_create(data) {
    return request('/objmedia/', 'post', {}, data)
}

export function objmedia_list(args, data) {
    return request('/objmedia/', 'get', args, data)
}

export function objmedia_patch(data) {
    let data_id = data.id
    delete data.id
    return request('/objmedia/' + data_id + '/', 'patch', {}, data)
}