var medata = null;
var data_routes = null;
var eors = null;
var rs = []
// var backend_url = 'http://127.0.0.1:9000/'
// var backend_url = 'http://39.99.142.217:8090/'
var backend_url = 'http://47.95.217.209:8200/'

function get_me_id() {
    if (!medata) {
        medata = JSON.parse(window.localStorage.getItem('me'));
    }
    if (!medata) {
        return null
    }
    return medata.id;
}

function get_medata() {
    if (!medata) {
        medata = JSON.parse(window.localStorage.getItem('me'));
    }
    if (!medata) {
        return null
    }

    return medata;
}

function get_data_routes() {
    return data_routes;
}

function set_data_routes(data) {
    data_routes = data;
}

function set_medata(data) {
    medata = data;
}

function is_mine(model) {
    return model.creator.id === get_me_id();
}


function get_eors() {
    return eors;
}

function set_eors(data) {
    eors = data;
}

function get_rs() {
    return rs;
}

function set_rs(data) {
    rs = data;
}


function get_backend_url() {
    return backend_url;
}


export default {
    is_mine,
    get_me_id,
    get_medata,
    set_medata,
    get_data_routes,
    set_data_routes,
    get_eors,
    set_eors,
    get_backend_url,
    set_rs,
    get_rs
}