import Vue from 'vue'
import Router from 'vue-router'

import Nav from '@/components/Nav'
import ToolBar from '@/components/ToolBar'
import ToolBarBlank from '@/components/ToolBarBlank'
import UserList from '@/views/UserList'
import ProductList from '@/views/ProductList'
import UserProductList from '@/views/UserProductList'
import MediaList from '@/views/MediaList'
import ObjMediaList from '@/views/ObjMediaList'


const Auth_PasswordReset = () =>
    import ('@/views/Auth_PasswordReset');
const Auth_PasswordSet = () =>
    import ('@/views/Auth_PasswordSet');
const Auth_SignIn = () =>
    import ('@/views/Auth_SignIn');
const Auth_SignOut = () =>
    import ('@/views/Auth_SignOut');
const Auth_SignUp = () =>
    import ('@/views/Auth_SignUp');

Vue.use(Router)


export default new Router({
    mode: 'history',
    base: '',
    routes: [{
        path: "/",
        name: "index",
        components: {
            default: UserList,
            toolbar: ToolBar,
            nav: Nav
        },
        meta: {
            title: "首页",
            requireAuth: true
        }
    },{
        path: "/user",
        name: "user",
        components: {
            default: UserList,
            toolbar: ToolBar,
            nav: Nav
        },
        meta: {
            title: "用户列表",
            requireAuth: true
        }
    },{
        path: "/product",
        name: "product",
        components: {
            default: ProductList,
            toolbar: ToolBar,
            nav: Nav
        },
        meta: {
            title: "商品列表",
            requireAuth: true
        }
    },{
        path: "/media",
        name: "media",
        components: {
            default: MediaList,
            toolbar: ToolBar,
            nav: Nav
        },
        meta: {
            title: "资源列表",
            requireAuth: true
        }
    },{
        path: "/objmedia",
        name: "objmedia",
        components: {
            default: ObjMediaList,
            toolbar: ToolBar,
            nav: Nav
        },
        meta: {
            title: "资源关联列表",
            requireAuth: true
        }
    },{
        path: "/user_product",
        name: "index",
        components: {
            default: UserProductList,
            toolbar: ToolBar,
            nav: Nav
        },
        meta: {
            title: "购买列表",
            requireAuth: true
        }
    }, {
        path: "/about/",
        name: "about",
        components: {
            toolbar: ToolBar,
        },
        meta: {
            title: "关于",
            requireAuth: false
        }
    }, {
        path: '/auth/signin/',
        name: 'signin',
        components: {
            default: Auth_SignIn,
            toolbar: ToolBarBlank
        },
        meta: {
            title: "登录"
        }
    }, {
        path: '/auth/signup/',
        name: 'signup',
        components: {
            default: Auth_SignUp,
            toolbar: ToolBarBlank
        },
        meta: {
            title: "注册"
        }
    }, {
        path: '/auth/signout/',
        name: 'signout',
        components: {
            default: Auth_SignOut,
            toolbar: ToolBarBlank
        },
        meta: {
            title: "退出",
            requireAuth: false
        },
    }, {
        path: '/auth/password/reset',
        name: 'passwordreset',
        components: {
            default: Auth_PasswordReset,
            toolbar: ToolBar
        },
        meta: {
            title: "密码重置"
        }
    }, {
        path: '/auth/password/set',
        name: 'passwordset',
        components: {
            default: Auth_PasswordSet,
            toolbar: ToolBar,
            requireAuth: false
        },
        meta: {
            title: "密码重置"
        }
    }, ]
})